

.navContainer {
    position: sticky;
    width: 50px;
    height: 50px;
    left: 30px;
    top: 30px;
    backdrop-filter: blur(16px); 
    background: #191926bb;
    background: #22223388;
    z-index: 1;
    border-radius: 4px;
    /* border: 1.8px solid #ffffff11; */
    border: 1.8px solid #ffffff11;
}

.nav2 {
    position: fixed;
    width: 100px;
    height: auto;
    right: 30px;
    top: 30px;
    backdrop-filter: blur(28px);
    /* background: #000008bb; */
    background: #191926cc;
    z-index: 1;
    border-radius: 4px;
    border: 1.8px solid #ffffff11;
    display: inline-block;
    padding: 8px;
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: 12px;
    font-weight: 200;
    color: #ffffff; 
}

.navContainer:hover {
    /* width: 54px;
    height: 54px;
    transition: 0.2s;
    margin-bottom: -2px; */
}

.pfpImg {
    width: 100%;
    border-radius: 4px;
    background: transparent;
    /* box-shadow: 0px 0px 80px black; */
}

.homeIcon {
    margin-top: 50%;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    color: red;
}