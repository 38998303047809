.page {
    padding: 0;
    margin: 0;
    font-family: 'Be Vietnam Pro', san-serif;
    overflow-x: hidden;
    background: black;
    color: white;
    margin-top: -4px;
    outline: 10px solid black;
}

.info {
    padding: 12px 6px;
    opacity: 0;
    width: 240px;
    height: auto;
    border: 1.8px solid #ffffff11;
    border-radius: 4px;
    position: fixed;
    backdrop-filter: blur(28px);
    background: #000008bb;
    background: red;
    padding-bottom: 20px;

    /* bottom: 30px;
    right: 30px; */
    top: 50;
    left: 50;
    z-index: 10;
    font-family: 'Be Vietnam Pro', sans-serif;
    color: #fff;
    transition: 0.1s;
    pointer-events: none;
}

.cursor {
    padding: 12px 6px;
    opacity: 0;
    width: 240px;
    height: auto;
 
    border: 1.8px solid #ffffff11;

    border-radius: 4px;
    position: fixed;
    backdrop-filter: blur(18px); 
    background: #191926bb;
    background: #222233bb;
    padding-bottom: 20px;

    z-index: 10;
    font-family: 'Be Vietnam Pro', sans-serif;
    color: #fff;
    transition: 0.1s;
    position: fixed; /* Floating above */
    transition: 0.2s; /* Sticking effect */
    transition-timing-function: ease-out;
    pointer-events: none; /* Allow clicking trough the div */

    will-change: transform;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
} 

.cursorvisible {
    opacity: 1;
}
/*
.spotlight {
    position: fixed;
    z-index: 2;
    pointer-events:none;
    animation-name: light;
    animation: light 4s forwards; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(circle at 150px calc(100% - 20px), rgba(198, 205, 255, 0.30) 80px, rgba(0, 0, 0, 0.84) 300px);
    opacity: 0;
} */


@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
} 


@keyframes light {
    0% {
        opacity: 0;
    }
    48% {
        opacity: 1;
    }
    52% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
} 

.hoverDates {
    margin-left: 12px;
    position: relative;
    display: flex;
    font-size: 12px;
    margin-top: 0px;
    font-weight: 200;
    margin-bottom: -16px;
    color: #ffffffaa;
}

.hoverProjName {
    margin-left: 12px;
    margin-top: 4px;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: -4px;
}

.negmargin {
    margin-bottom: -8px;
}

.hoverProjRole {
    margin-left: 16px;
    font-weight: 200;
    font-size: 12px;
    margin: 12px 12px;
    margin-bottom: -4px;
    margin-top: 12px;
    color: #ffffffaa;
}

.hoverInspoSubtext {
    margin-left: 16px;
    font-size: 12px;
    font-weight: 200;
    margin: 12px 12px;
    margin-bottom: -4px;
    margin-top: 12px;
    line-height: 18px;
    color: #ffffffaa;
}


.hoverClickText {
    margin-left: 12px;
    font-size: 12px;
    margin-bottom: -6px;
    margin-top: 14px;
    font-weight: 300;
    /* font-style: italic; */
    color: #ffffffaa;
}

.hoverProjDesc {
    font-size: 12px;
    margin-bottom: -4px;
    margin: 12px 12px;
    margin-bottom: 0px;
    margin-top: 8px;
    color: #ffffffaa;
    /* font-style: italic; */
}

.statusIcon {
    height: 8px;
    width: 8px;
    margin-left: 8px;
    margin-top: 16px;
    border-radius: 50%;
    background: #ffffff22;
}

.white{
    color: white;
}

.lightGrey {

}

.statusPresent {
    background: #22E29D;
    box-shadow: 0px 0px 6px #22E29D55;
}

.me {
}

.meImg {
    pointer-events: none;
    z-index: 1;
    top: 0;
    width: auto;
    height: 200px;
    position: fixed;
    top: 30px;
    right: 30px;
    border-radius: 4px;
    /* animation: fadein;
    animation-duration: 0.2s; */
}


.memento-cursor {

}

.italic {
    font-style: italic;
    letter-spacing: 2%;
}

.spotifyDiv {
    display: flex;
    color: #0CF5A3;
}

.spotifyCircle {
    display: flex;
    /* border-radius: 100px; */
    height: 10px;
    width: 10px;
    animation-name: pulse;
    animation-delay: 0;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); 
}

.spotifyLine1 {
    background: #0CF5A3;
    width: 2px;
    height: 5px;
    margin-top: 50%;
    transform: translateY(-50%);
    margin-right: 2px;
    animation-name: blip;
    animation-delay: 0;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    /* animation-direction: alternate;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); */
    /* margin-top: 50%;
    margin-left: 50%;
    transform: translate(-50%, -50%); */
    /* margin-right: 2px; */
}

.spotifyLine2 {
    background: #0CF5A3;
    width: 2px;
    height: 10px;
    margin-top: 50%;
    transform: translateY(-50%);
    margin-right: 2px;
    animation-name: blipbig;
    animation-delay: 0.4s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    /* animation-direction: alternate;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); */
    /* margin-top: 50%;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    margin-right: 2px; */
}

.spotifyLine3 {
    background: #0CF5A3;
    width: 2px;
    height: 5px;
    margin-top: 50%;
    transform: translateY(-50%);
    animation-name: blip;
    animation-delay: 0.8s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    /* animation-direction: alternate; */
    /* transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); */
    /* margin-top: 50%;
    margin-left: 50%;
    transform: translate(-50%, -50%); */
}

.spotifyLine1o {
    background: #444455;
    width: 2px;
    height: 5px;
    margin-top: 50%;
    transform: translateY(-50%);
    margin-right: 2px;
}

.spotifyLine2o {
    background: #444455;
    width: 2px;
    height: 10px;
    margin-top: 50%;
    transform: translateY(-50%);
    margin-right: 2px;
}

.spotifyLine3o {
    background: #444455;
    width: 2px;
    height: 5px;
    margin-top: 50%;
    transform: translateY(-50%);
}

.spotifyCircleGray {
    background: #212433;
    border-radius: 100px;
    height: 10px;
    width: 10px;
}


.spotifyText {
    font-size: 16px;
    font-weight: 300;
    margin-left: 10px;
    margin-top: -5px;
}

.spotifyTextGrey {
    color: #444455;
    font-size: 16px;
    font-weight: 300;
    margin-left: 10px;
    margin-top: -5px;
}

.empty {
    margin-top: 30px;
}

@keyframes pulse {
    from {
        opacty: 1;
    }

    40% {
        opacty: 1;
    }

    50% {
        opacty: 0.6;
    }

    90% {
        opacty: 0.6;
    }
  
    to {
        opacty: 0.6;
    }
  }

  @keyframes blip {
    from {
        height: 5px;
    }

    20% {
        height: 6px;
    }
  
    40% {
        height: 3px;
    }

    60% {
        height: 8px;
    }
    80% {
        height: 5px;
    }
  
    to {
        height: 5px;
    }
  }

  @keyframes blipbig {
    from {
        height: 10px;
    }

    20% {
        height: 12px;
    }
  
    40% {
        height: 6px;
    }

    60% {
        height: 14px;
    }

    90% {
        height: 10px;
    }
  
    to {
        height: 10px;
    }
  }

.navContainer {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
}

.introContainer {
    margin-left: max(20vw, calc((100vw - 1200px)/2)); 
    margin-top: 200px;
    margin-bottom: 180px;
}

.helloText {
    font-size: 36px;
    font-weight: 200;
    width: 745px;
    padding: 0;
}

.studentText, .aboutText {
    font-size: 16px;
    font-weight: 200;
    /* letter-spacing: -0.04rem; */
    /* color: #ffffffaa; */
    width: 735px;
    margin-top: 36px;
}

.nameDiv {
    width: auto;
    height: auto;
    cursor: ne-resize;
    background: #191926;
    /* background: linear-gradient(217deg, #19192699, #2f2f4555 70.71%),
            linear-gradient(127deg, #13131dee, #2d2d4899 70.71%),
            linear-gradient(336deg, #2f2f4555, #2f2f45 70.71%); */
    padding: 1px 8px;
    border-radius: 4px;
}

@keyframes flow {
    from {
        background: linear-gradient(217deg, #19192699, #2f2f4555 70.71%),
            linear-gradient(127deg, #13131dee, #2d2d4899 70.71%),
            linear-gradient(336deg, #2f2f4555, #2f2f45 70.71%);
    }

    40% {
        background: linear-gradient(217deg, #2d2d48, #2f2f4555 70.71%),
            linear-gradient(127deg, #13131dee, #13131dee 70.71%),
            linear-gradient(336deg, #2f2f4555, #2d2d4899 70.71%);
    }

    50% {
        background: linear-gradient(217deg, #13131dee ,#2f2f45 70.71%),
            linear-gradient(127deg, #13131dee, #13131dee 70.71%),
            linear-gradient(336deg, #19192699, #2d2d4899 70.71%);
    }

    90% {
        opacty: 0.6;
    }
  
    to {
        opacty: 0.6;
    }
  }

.introHighlight {
    position: absolute;
    background: #AAA3FF33;
    width: 160px;
    height: 8px;
    top: 398px;
}

.kanye {
    margin-top: 240px;
}

.kanyeImg {
    width: calc(80% - 10px);
    margin-left: 20%;
    filter: contrast(1.3);
    filter: brightness(1.1);
    filter: saturate(1.25);
}

.inspoTopContainer {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
}

.vertContainer {
    width: 38vw;
    margin-left: 10px;
    /* width: 39%;
    display: flex;
    flex-direction: column;
    height: intrinsic; 
    margin: 0px;
    margin-left: 10px;
    border-radius: 4px; */
}

.vertImg {
    width: 100%;
    margin-top: 10px;
    margin-bottom: -4px;
    filter: saturate(1.25);
    flex-direction: row;
    height: auto;
    border-radius: 4px;
}

.horizContainer {
    width: 62vw;
    display: flex;
    flex-direction: column;
    height: auto;
    margin-right: 10px;
    border-radius: 4px;
}

.threeContainer {
    width: 100%;
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    height: 41%;
    flex-direction: row;
    overflow: hidden;
}

.workIndicator {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-left: 20px;
    background: #22E29D;
    box-shadow: 0px 0px 6px #22E29D55;
    /* animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate; */
}

@keyframes blink {
    from {background: #22E29D;
        box-shadow: 0px 0px 6px #22E29D77;}
    to {background: #22E29D77;
        box-shadow: 0px 0px 6px #22E29D00;}
}

/* Work Imgs */

.mementoImgContainer {
    width: calc(100% - 20px);
    margin-left: 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

.mementoImg {
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
}

.flex {
    display: flex;
    flex-direction: row;
}

.bold {
    font-weight: 600;
}

.workLeftContainer {
    margin-left: 10px;
    flex-direction: row;
    width: 40%;
    height: auto;
}

.workRightContainer {
    width: calc(100% - 40%);
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    height: auto;
    border-radius: 4px;
}

.workImgContainer {
    margin-bottom: 10px;
}

.astaImg {
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
}

.webpointImg {
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
}

.blenderImg {
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
}

.lpImg {
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
}

.miscImg {
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
}

/* About Imgs */

.yuvalImgContainer {
    width: 70%;
}

.yuvalImg {
    width: 100%;
}

.threeSideConatiner {
    width: calc(45% - 0px);
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.chamathImgContainer {
    width: 100%;
    margin-bottom: 4px;
}

.chamathImg, .jojiImg {
   width: 100%;
   border-radius: 4px;
}

.ddImg {
    width: 100%;
    object-fit: contain;
    border-radius: 4px;
}

.hisaishiImg {
    margin-top: 10px;
    width: 100%;
    border-radius: 4px;
}

.twoContainer {
    width: 100%;
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.footerText {
    text-align: center;
    display: flex;
    font-size: 14px;
    margin-bottom: 48px;
    margin-top: 48px;
    width: 420px;
    font-weight: 300;
    margin-left: max(20vw, calc((100vw - 1200px)/2)); 
    text-decoration: none;
    /* margin-left: 50%;
    transform: translateX(-50%); */
    justify-content: space-between;
}

.footerC {
    text-align: center;
    color: #84889988;
    margin-right: 16px;
}

.footerL {
    text-align: center;
    margin-right: 16px;
    color: #84889988;
    text-decoration: none;
}

.footerL:hover {
    text-align: center;
    color: white;
    cursor: pointer;
    transition: 0.2s;
}

.kanyeImg:hover, 
.vertImg:hover,
.yuvalImgContainer:hover,
.chamathImgContainer:hover,
.ddImgContainer:hover,
.jojiImg:hover,
.hisaishiImg:hover 
.mementoImg:hover
.blenderImg:hover
.lpImg:hover
.miscImg:hover
.webpointImg:hover    {
    /* transition: 0.4s;
    filter: brightness(0.5); */
    /* cursor: pointer; */
}

.aboutTextContainer {
    margin: 180px 0px;
    margin-left: 20%;
}


@media (max-width: 1450px) {
    .vertContainer {
        width: 38vw;
    }
    .horizContainer {
        width: 62vw;
    }

}

@media (max-width: 1250px) {
    .meImg {
        height: 150px;
        /* animation: fadein;
        animation-duration: 0.2s; */
    }

    .introContainer {
        margin-left: 10%;
    }
    .footerText {
        margin-left: 10%;
    }

    .aboutTextContainer {
        margin-left: 10%;
    }
    
    .helloText {
        font-size: 32px;
        width: 645px;
    }
    
    .studentText, .aboutText {
        font-size: 16px;
        width: 675px;
    }
}

@media (max-width: 1050px) {
}

@media (max-width: 850px) {

    .introContainer {
        margin-left: 20px;
    }

    .footerText {
        margin-left: 20px;
    }

    .aboutTextContainer {
        margin-left: 20px;
    }

    .meImg {
        height: 120px;
        /* animation: fadein;
        animation-duration: 0.2s; */
    }
    
    .helloText {
        font-size: 24px;
        width: 80%;
    }

    .spotifyText {
        font-size: 12px;
        margin-top: -2px;
    }

    .spotifyCircle {
        transform: scale(0.8);
    }
    
    .studentText, .aboutText {
        font-size: 12px;
        width: 90%;
        margin-bottom: 120px;
    }
    .interestsContainer {
        margin-top: -80px;
    }   
    .aboutTextContainer {
        margin-top: 80px;
    }
    
}

@media (max-width: 625px) {

    .footerText {
        font-size: 11px;
    }

    .studentText, .aboutText {
        font-size: 12px;
    }

    .spotifyText {
        font-size: 12px;
        margin-top: -2px;
        width: 96vw;
    }

    .meImg {
        display: none;
    }

    .cursor {
        display: none;
    }

    .spotlight {
        display: none;
    }

    .cursor {
        position: fixed;
    }

    .page {
        padding-left: 34px;
        padding-right: 30px;
        width: 101vw;
        margin: 0;
    }
    .threeSideConatiner {
        margin: 0;
        padding: 0;
        width: 96vw;
        display: inline-block;
    }

    .inspoTopContainer {
        margin: 0;
        padding: 0;
        width: 96vw;
        display: inline-block;
    }

    .vertContainer {   
        margin: 0;
        padding: 0;
        width: 96vw;
        display: inline-block;
    }

    .horizContainer {
        margin: 0;
        padding: 0;
        width: 96vw;
        margin-bottom: 10px;
        display: inline-block;
    }

    .threeContainer {
        margin: 0;
        padding: 0;
        width: 96vw;
        margin-bottom: 10px;
        display: inline-block;
    }

    .flex {
        display: inline-block;
    }

    .vertImg {
        margin: 0;
        padding: 0;
        width: 96vw;
        margin-bottom: 10px;
    }

    .yuvalImgContainer {
        margin: 0;
        padding: 0;
        width: 96vw;    
        margin-bottom: 10px;
    }

    .chamathImgContainer {
        margin: 0;
        padding: 0;
        width: 96vw;
        margin-left: -10px;
        margin-bottom: 10px;
    }

    .twoContainer {
        margin: 0;
        padding: 0;
        width: 96vw;
        display: inline-block;
    }

    .interestsContainer {
        margin-top: -100px;
    }   
    .cursor {
        left: 0px;
        left: 50%;
        transform: translateX(-50%);
    }

    .yuvalImgContainer {
        width: 96vw;
    }

    .ddImg {
        width: 96vw;
        object-fit: contain;
        border-radius: 4px;
        margin-left: -10px;
    }

    .threeSideConatiner {
        width: 96vw;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
    }

    .workLeftContainer {
        display: inline-block;
        margin: 0;
        width: 96vw;
    }
    
    .workRightContainer {
        display: inline-block;
        margin: 0;
        width: 96vw;
    }

    .mementoImg {
        width: 96vw;
        cursor: pointer;
        border-radius: 4px;
    }

    .spotifyDiv {
        margin-bottom: 32px;
    }

    .helloText {
        font-size: 24px;
        width: 96vw;
        margin: 0;
        margin-bottom: 48px;
    }

    .introContainer {
      margin: 0;
      margin-top: 200px;
      margin-bottom: 240px;
    }

    .studentText, .aboutText {
        width: 96vw;
        margin:0;
        margin-top: 32px;
        /* margin-bottom: 260px; */
    }

    .mementoImg {
        width: 98vw;
        margin-bottom: 10px;
        margin-left: -2vw;
    }

    .aboutTextContainer {
        width: 96vw;
        margin:0;
        margin-top: 160px;
        margin-bottom: 260px;
    }
}


